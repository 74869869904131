<template>
    <v-chart class="echarts" :options="options" width :autoresize="true"/>
</template>

<script>
    import ECharts from 'vue-echarts'
    import Vue from 'vue'

    Vue.component('v-chart', ECharts)
    import 'echarts/lib/chart/bar'
    import 'echarts/lib/component/dataZoom'
    import 'echarts/lib/component/legend'

    export default {
        components: {
            'v-chart': ECharts
        },
        props:{
            filter:{
                type: Object,
                default:()=>{ return {} }
            }
        },
        data() {
            return {
                options: {
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                            },
                        }]
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            start: 0,
                            end: 35
                        }
                    ],
                    xAxis: [{
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false
                        },
                        type: 'category',
                        data: [],
                        axisLabel: {
                            interval: 0,
                            rotate: 60
                        }
                    }],
                    yAxis: {
                        type: 'value',
                        show: false
                    },
                    series: [
                        {
                            name: 'value_1',
                            type: 'bar',
                            stack: 'total',
                            barWidth: "40%",
                            label: {
                                show: true,
                                position: 'inside'
                            },

                            color: '#1473E2',
                            data: [320, 302, 301],
                        },
                        {
                            name: 'value_2',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            color: '#FF9F00',
                            data: [120, 132, 101]
                        },
                        {
                            name: 'value_3',
                            type: 'bar',
                            stack: 'total',
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            color: '#DD0A0A',
                            data: [220, 182, 191]
                        }
                    ]
                },
                assurance_list: []
            }
        },
        methods:{
            findDossier(){
                log('findDossier');
                let match = {'action.task_status': 'active' };
                let now = new Date().getTime();
                let date_creation = {}

                if(this.filter.dateDebut){
                    date_creation.$gt = this.filter.dateDebut;
                    match.date_creation = date_creation
                }
                else if(this.filter.dateFin){
                    date_creation.$lt = this.filter.dateFin;
                    match.date_creation = date_creation
                }
                else if(this.filter.dateDebut && this.filter.dateFin){
                    date_creation.$gt = this.filter.dateDebut;
                    date_creation.$lt = this.filter.dateFin;
                    match.date_creation = date_creation
                }

                let pipeline = [
                    {
                        $facet: {
                            'encours': [
                                {$match: {...match, deadline_warn: {$gt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {
                                    $group: {
                                        _id: "$dossier.assurance.label",
                                        count: {$sum: 1}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ],
                            'en_approche': [
                                {$match: {...match, deadline_warn: {$lt: now},deadline: {$gt: now} } },
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {
                                    $group: {
                                        _id: "$dossier.assurance.label",
                                        count: {$sum: 1}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ],
                            'hors_delai': [
                                {$match: {...match, deadline: {$lt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {
                                    $group: {
                                        _id: "$dossier.assurance.label",
                                        count: {$sum: 1}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ]

                        }
                    }
                ];
                let args = {
                    collection: this.$SHARED.collections.documents,
                    pipeline: pipeline
                };
                this.$store.requestAggregate(args).then(data => {
                    this.setEchartData(data[0])
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                })
            },
            getAssurancesList() {
                log('getAssurancesList');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    concat_pipeline: [
                        {
                            $project: {
                                label: 1,
                                order: {$ifNull: ["$order", 1000]}
                            }
                        },
                        {$sort: {order: 1}}
                    ],
                    options: {
                        page: 1, limit: -1,
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs.map(assurance => assurance.label);
                    this.findDossier();
                    this.options.xAxis[0].data = this.assurance_list;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            setEchartData(stats){
                let echartData = {
                    encours: [],
                    en_approche: [],
                    hors_delai: []
                }
                let data = {}
                this.assurance_list.forEach((assurance)=>{
                    data = _.find(stats.encours, {_id: assurance})
                    echartData.encours.push(data ? data.count : 0)

                    data = _.find(stats.en_approche, {_id: assurance})
                    echartData.en_approche.push(data ? data.count : 0)

                    data = _.find(stats.hors_delai, {_id: assurance})
                    echartData.hors_delai.push(data ? data.count : 0)
                });
                this.options.series[0].data = echartData.encours
                this.options.series[1].data = echartData.en_approche
                this.options.series[2].data = echartData.hors_delai
            }
        },
        computed:{
            current_user(){
                return this.$store.state.current_user;
            }
        },
        watch:{
            filter:{
                deep: true,
                handler(){
                    this.findDossier();
                }
            }
        },
        mounted() {
            this.getAssurancesList()
        }
    }
</script>

<style scoped>
    .echarts {
        width: 100%;
        height: 249px;
    }
</style>