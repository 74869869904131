<template>
	<div class="ui grid m-0 p-0">
        <div class="ui grid row p-0 m-0">
			<sidebar />
            <div class="thirteen wide column p-0">
                <navbar/>
               <div class="ui stackable grid m-0">
                    <div class="row">
                        <div class="eight wide column">
                            <h1 class="greeting">Bonjour !</h1>
                        </div>
                        <div class="eight wide column">
                            <div class="date float-right">
                                <p class="label">Aujourd’hui <i class="angle down icon"></i></p>
                                <p class="value">{{new Date() | moment('L')}}</p>
                            </div>
                        </div>
                    </div>
                   <div class="row px-1 ">
                       <div class="column filter-container">
                           <div class="filter-label">FILTRER PAR :</div>
                           <div class="ui form">
                               <div class="three fields">

                                   <div class="field">
                                       <label>Date début</label>
                                       <date-milli v-model="filter.dateDebut"/>
                                   </div>

                                   <div class="field">
                                       <label>Date fin</label>
                                       <date-milli v-model="filter.dateFin"/>
                                   </div>

                                   <div class="field">
                                       <label>Compagnie</label>
                                       <v-select :reduce="ass => ass._id" label="label" placeholder="Compagnie"
                                       :options="assurance_list" v-model="filter.selected_assurance"/>
                                   </div>

                               </div>

                               <div style="margin: 10px 0">
                                   <button class="ui right floated button" style="border-radius: 6px;" @click="clear">
                                       Re-initialiser
                                   </button>
                               </div>
                               <div style="clear: both"></div>

                           </div>
                       </div>
                   </div>
					<div class="row centered pt-0 items-center px-1">
                        <div class="two wide column">
                            <div class="foldersCount">
                                <p class="value">{{stats.all}}</p>
                                <p class="label">dossiers en attente</p>
                            </div>
                        </div>
						<div class="seven wide column px/3">
                            <home-chart :filter="filter" />
                        </div>
					
						<div class="two wide column px/1" style="margin-right: 7px;">
							<progression-stats :statInfo="{value: stats.encours, label:'dossiers en cours', type:'en_attente'}"></progression-stats>
						</div>
						<div class="two wide column px/1" style="margin-right: 7px;">
							<progression-stats :statInfo="{value:stats.en_approche,label:'dossiers en approche', type:'en_approche'}"></progression-stats>
						</div>
						<div class="two wide column px/1">
							<progression-stats :statInfo="{value:stats.hors_delai,label:'dossiers hors delai', type:'hors_delai'}"></progression-stats>
						</div>
					</div>
					     <!-- <div class="row centered pt-0">
                        <div class="twelve wide column">
                            <div class="ui big form">
                                <div class="field">
                                    <label class="homeSearchLabel">Trouver un dossier</label>
                                    <div class="position-relative">
                                        <span class="position-absolute input-icon" @click="setSearchQuery">
                                            <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M20.193 3.46c-4.613-4.613-12.121-4.613-16.734 0-4.612 4.614-4.612 12.12 0 16.735 4.108 4.107 10.506 4.547 15.116 1.34.097.459.319.897.676 1.254l6.718 6.718a2.498 2.498 0 003.535 0 2.496 2.496 0 000-3.535l-6.718-6.72a2.5 2.5 0 00-1.253-.674c3.209-4.611 2.769-11.008-1.34-15.118zm-2.121 14.614c-3.444 3.444-9.049 3.444-12.492 0-3.442-3.444-3.442-9.048 0-12.492 3.443-3.443 9.048-3.443 12.492 0 3.444 3.444 3.444 9.048 0 12.492z"
                                                    fill="#2F80ED" /></svg>
                                        </span>
                                        <input type="text" class="homeSearchInput" v-model="search" @keypress.enter="setSearchQuery"
                                            placeholder="Veuillez saisir le numéro  du dossier . . .">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
					<dashboard-tables :stats="stats" :filter="filter"></dashboard-tables>
					<div class="row pt-0">
                        <div class="six wide column">
							<operator-status :stats="stats" :filter="filter"/>
						</div>
                        <div class="ten wide column">
							<operator-progress :filter="filter"/>
						</div>
					</div>
               </div>
            </div>
        </div>
        
    </div>
</template>

<script>
	import navbar from '@/components/navbar.vue'
	import sidebar from '@/components/sidebar.vue'
	import homeChart from '@/components/home-chart.vue'
	import ProgressionStats from '@/components/progression-stats.vue'
	import DashboardTables from '@/components/dashboard-tables.vue'
	import OperatorProgress from '@/components/operator-progress.vue'
	import OperatorStatus from '@/components/operator-status.vue'
    import dateMilli from "@/components/dateMilli.vue"
	export default {
		name: "Dashboard",
		data() {
			return {
                stats:{
                    all:0,
                    encours:0,
                    en_approche:0,
                    hors_delai:0,
                },
                search: '',
                assurance_list: [],
                filter:{
                    selected_assurance: null,
                    dateDebut: null,
                    dateFin: null,
                }
			}
		},
		components: {
			navbar,sidebar,homeChart,
			ProgressionStats,
			DashboardTables,
            OperatorProgress,
            OperatorStatus,
            dateMilli
		},
        computed:{
            current_user(){
                return this.$store.state.current_user;
            }
        },
		methods: {
            getStats(){
                let selective_query = {}
                let match_dossier = {}
                let now = new Date().getTime();

                if(this.filter.selected_assurance){
                    match_dossier["dossier.assurance._id"] = this.filter.selected_assurance
                }

                if(this.filter.dateDebut || this.filter.dateFin)
                    selective_query.date_creation = this.$store.dateToQuery(this.filter.dateDebut, this.filter.dateFin)

                console.log("selective", selective_query);
                let pipeline=[
                    {$match:{...selective_query, type:'link_task', 'action.task_status':'active'}},
                    {
                        $facet: {
                            'all': [
                                {$count: 'total'},
                            ],
                            'encours': [
                                {$match: { deadline_warn: {$gt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {$count: 'total'},
                            ],

                            'en_approche': [
                                {
                                    $match: {
                                        deadline_warn: {$lt: now},
                                        deadline: {$gt: now}
                                    }
                                },
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {$count: 'total'}
                            ],
                            'hors_delai': [
                                {$match: {deadline: {$lt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {$count: 'total'}
                            ]

                        }
                    },
                    {$unwind: {"path": "$all"}},
                    {$unwind: {"path": "$encours", preserveNullAndEmptyArrays: true}},
                    {$unwind: {"path": "$hors_delai", preserveNullAndEmptyArrays: true}},
                    {$unwind: {"path": "$en_approche", preserveNullAndEmptyArrays: true}},
                    {
                        $project: {
                            "all": "$all.total",
                            "encours": "$encours.total",
                            "hors_delai": "$hors_delai.total",
                            "en_approche": "$en_approche.total"
                        }
                    },]
                console.log("PIPELINE",
                    JSON.stringify(pipeline))
                this.$store.requestAggregate({collection:'documents', pipeline:pipeline}).then(result => {
                    console.log("STATS",result[0]);
                    //this.stats = Object.assign({}, this.stats, result[0])
                    let stats_keys = ['all', 'encours', 'en_approche', 'hors_delai']
                    stats_keys.forEach(key =>{
                        if(_.has(result[0], key)){
                            this.stats[key] = result[0][key]
                        } else{
                            this.stats[key] = 0
                        }
                    })

                    //_.assign(this.stats, result[0])
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'warn',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            setSearchQuery(){
			    this.$router.push({name: 'home', params:{search_prop: this.search}});
            },
            getAssurancesList() {
                log('getAssurancesList');
                const data = {
                    collection: this.$SHARED.collections.assurance,
                    query: {},
                    options: {page: 1, limit: -1, sort: {label: 1}}
                };
                this.$store.requestFind(data).then(ar => {
                    this.assurance_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            clear(){
                this.filter={
                    selected_assurance: null,
                    dateDebut: null,
                    dateFin: null,
                }
            }
		},
        watch:{
            filter:{
                deep: true,
                handler(){
                    this.getStats();
                }
            }
        },
		mounted() {
		    this.getStats();
		    this.getAssurancesList();
		}
	};
</script>
<style scoped>
    .filter-container {
        padding: 1em !important;
        border: 2px solid #062a55;
        border-radius: 10px;
        position: relative !important;
    }

    .filter-label {
        position: absolute;
        top: -10px;
        font-weight: 700;
        font-size: 1em;
        background-color: white;
        padding: 0 0.5em;
    }
</style>