<template>
    <div class="ui fluid card statsCard">
        <div class="foldersCount">
            <p class="value" :class="label_types[statInfo.type]">{{statInfo.value}}</p>
            <p class="label" style="font-size: 1.1em">{{statInfo.label}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        statInfo: {
            type: Object,
        },
    },
    data() {
        return {
            label_types: {
                'hors_delai':"red",
                'en_attente':"blue",
                'en_approche':"yellow",

            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>