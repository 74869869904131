<template>
    <div class="row">
        <div class="eight wide column">
            <h1 class="folder-count" style="color: #1473E2">Dossiers en cours <span
                    class="count">{{stats.encours}}</span></h1>
            <table class="ui very basic tablet stackable table folderTable p-half">
                <thead>
                <tr>
                    <th>N° dossier</th>
                    <th>Operateur</th>
                    <th>Temps d'exécution</th>
                    <th>Temps</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="task in tasks.encours">
                    <td>{{ task.dossier.numero ? task.dossier.numero : '------'}}</td>
                    <td class="tableAvatar">
                        <avatar :user="task.receiver"/>
                        <span>{{task.receiver.username}}</span>
                    </td>
                    <td>
                        {{ temps_exec(task) }} Minutes
                    </td>
                    <td class="text-green">
                        <!--<countdown :time="deadline(task)" :interval="1000" tag="div">
                            <template slot-scope="props">{{ props.days > 0 ? props.days+ ' jour(s),' : ''}}
                                {{ props.hours }} h, {{props.minutes }} m, {{props.seconds }} s
                            </template>
                        </countdown>-->
                        <custom-counter :deadline="task.deadline"/>
                    </td>
                </tr>

                </tbody>
                <!--<tfoot>
                <tr>
                    <td colspan="10" class="tablePlusBtn" style="cursor: pointer"
                        @click="$router.push({name: 'home'})">
                        <i class="plus circle icon"></i>
                        Plus
                    </td>
                </tr>
                </tfoot>-->
                <tfoot>
                <tr>
                    <th colspan="99" style="border: 0">
                        <div>
                            <pagination :limit="enCoursLimit" :current_page="enCoursPage" :count="enCoursCount"
                                        @setPage="setEnCoursPage" slot="pagination"/>
                        </div>
                    </th>
                </tr>
                </tfoot>
            </table>
        </div>
        <div class="eight wide column">
            <h1 class="folder-count negative">DossierS Hors delai <span class="count">{{stats.hors_delai}}</span></h1>

            <table class="ui very basic tablet stackable table folderTable p-half">
                <thead>
                <tr>
                    <th>N° dossier</th>
                    <th>Operateur</th>
                    <th>Temps d'exécution</th>
                    <th>Temps</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="task in tasks.hors_delai">
                    <td>{{ task.dossier.numero }}</td>
                    <td class="tableAvatar">
                        <avatar :user="task.receiver"/>
                        <span>{{task.receiver.username}}</span>
                    </td>
                    <td>
                        {{ temps_exec(task) }} Minutes
                    </td>
                    <!--<td class="text-red">
                        <countdown :time="deadline(task)" :interval="1000" tag="div">
                            <template slot-scope="props">{{ props.days > 0 ? props.days+ ' jour(s),' : ''}}
                                {{ props.hours }} h, {{props.minutes }} m, {{props.seconds }} s
                            </template>
                        </countdown>
                    </td>-->
                    <td class="text-red">
                        <countupdown :deadline="task.deadline" :key="task.dossier.numero"/>
                    </td>
                </tr>

                </tbody>
                <!--<tfoot>
                <tr>
                    <td colspan="10" class="tablePlusBtn" style="cursor: pointer"
                        @click="$router.push({name: 'home'})">
                        <i class="plus circle icon"></i> Plus
                    </td>
                </tr>
                </tfoot>-->
                <tfoot>
                <tr>
                    <th colspan="99" style="border: 0">
                        <div>
                            <pagination :limit="horsDelaiLimit" :current_page="horsDelaiPage" :count="horsDelaiCount"
                                        @setPage="setHorsDelaiPage" slot="pagination"/>
                        </div>
                    </th>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
    import avatar from "./avatar";
    import countupdown from "./countdown";
    import pagination from "@/components/pagination.vue";
    import CustomCounter from "./customCounter";

    export default {
        name: 'dashboard-tables',
        components: {CustomCounter, avatar, countupdown, pagination},
        props: {
            stats: '',
            filter: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                horsDelaiCount: 0,
                horsDelaiPage: 1,
                horsDelaiLimit: 5,

                enCoursCount: 0,
                enCoursPage: 1,
                enCoursLimit: 5,
                // tasks: [],
                tasks: {
                    encours: [],
                    hors_delai: []
                },
                counter: null
            }
        },
        methods: {
            setHorsDelaiPage(page) {
                this.horsDelaiPage = page;
                this.getHorsDelai();
            },
            setEnCoursPage(page) {
                this.enCoursPage = page;
                this.getEnCours();
            },
            findDossier() {
                log('findDossier');
                let match = {};
                let match_dossier = {};
                let now = new Date().getTime();

                if (this.filter.selected_assurance) {
                    match_dossier["dossier.assurance._id"] = this.filter.selected_assurance
                }

                if (this.filter.dateDebut || this.filter.dateFin)
                    match.date_creation = this.$store.dateToQuery(this.filter.dateDebut, this.filter.dateFin)

                let pipeline = [
                    {$sort: {date_creation: -1}},
                    {
                        $facet: {
                            'encours': [
                                {$match: {...match, 'action.task_status': 'active', deadline_warn: {$gt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {$limit: 5}
                            ],
                            'hors_delai': [
                                {$match: {...match, 'action.task_status': 'active', deadline: {$lt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {$limit: 5}
                            ]

                        }
                    }
                ];
                let args = {
                    collection: this.$SHARED.collections.documents,
                    pipeline: pipeline
                };
                this.$store.requestAggregate(args).then(data => {
                    // _.assign(this.tasks, data[0]);
                    this.tasks = data[0]
                    log('task', this.tasks)
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                })
            },
            getEnCours() {
                log('getEnCours');
                let match = {};
                let match_dossier = {};
                let now = new Date().getTime();

                if (this.filter.selected_assurance) {
                    //match_dossier["dossier.assurance._id"] = this.filter.selected_assurance
                    match_dossier["$or"] = [
                        {"dossier.assurance._id": this.filter.selected_assurance, "dossier.type_reference": "normale"},
                        {"dossier.adverse.assurance._id": this.filter.selected_assurance, "dossier.type_reference": "croise"}
                    ]
                }

                if (this.filter.dateDebut || this.filter.dateFin)
                    match.date_creation = this.$store.dateToQuery(this.filter.dateDebut, this.filter.dateFin)

                let pipeline = [
                    {$sort: {date_creation: -1}},
                    {$match: {...match, 'action.task_status': 'active', deadline_warn: {$gt: now}}},
                    {
                        $graphLookup: {
                            from: "documents",
                            startWith: "$id_doc",
                            connectFromField: "id_doc",
                            connectToField: "_id",
                            maxDepth: 1,
                            as: "dossier"
                        }
                    },
                    {$unwind: "$dossier"},
                    {$match: match_dossier},
                    /*{$skip: this.enCoursPage-1},
                    {$limit: this.enCoursLimit}*/
                ];
                let args = {
                    collection: this.$SHARED.collections.documents,
                    query: {},
                    concat_pipeline: pipeline,
                    options: {
                        page: parseInt(this.enCoursPage),
                        limit: parseInt(this.enCoursLimit)
                    }
                };
                this.$store.requestFind(args).then(data => {
                    // _.assign(this.tasks, data[0]);
                    this.tasks.encours = data.docs
                    this.enCoursCount = data.count
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                })
            },
            getHorsDelai() {
                log('getHorsDelai');
                let match = {};
                let match_dossier = {};
                let now = new Date().getTime();

                if (this.filter.selected_assurance) {
                    //match_dossier["dossier.assurance._id"] = this.filter.selected_assurance
                    match_dossier["$or"] = [
                        {"dossier.assurance._id": this.filter.selected_assurance, "dossier.type_reference": "normale"},
                        {"dossier.adverse.assurance._id": this.filter.selected_assurance, "dossier.type_reference": "croise"}
                    ]
                }

                if (this.filter.dateDebut || this.filter.dateFin)
                    match.date_creation = this.$store.dateToQuery(this.filter.dateDebut, this.filter.dateFin)

                let pipeline = [
                    {$sort: {date_creation: -1}},
                    {$match: {...match, 'action.task_status': 'active', deadline: {$lt: now}}},
                    {
                        $graphLookup: {
                            from: "documents",
                            startWith: "$id_doc",
                            connectFromField: "id_doc",
                            connectToField: "_id",
                            maxDepth: 1,
                            as: "dossier"
                        }
                    },
                    {$unwind: "$dossier"},
                    {$match: match_dossier},
                ];
                let args = {
                    collection: this.$SHARED.collections.documents,
                    query: {},
                    concat_pipeline: pipeline,
                    options: {
                        page: parseInt(this.horsDelaiPage),
                        limit: parseInt(this.horsDelaiLimit)
                    }
                };
                this.$store.requestFind(args).then(data => {
                    this.tasks.hors_delai = data.docs
                    this.horsDelaiCount = data.count
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                })
            },
            deadline(task) {
                let deadline = task.deadline - new Date().getTime();
                return (deadline > 0) ? deadline : 0
            },
            temps_exec(task) {
                let temps_exec = task.action.temps_exec ? task.action.temps_exec : 15;
                let timer = task.timer ? task.timer : 0
                return temps_exec - timer
            }
        },
        computed: {
            current_user() {
                return this.$store.state.current_user;
            }
        },
        watch: {
            filter: {
                deep: true,
                handler() {
                    // this.findDossier();
                    this.getEnCours();
                    this.getHorsDelai();
                }
            }
        },
        mounted() {
            //this.findDossier();
            this.getEnCours();
            this.getHorsDelai();
        }
    }
</script>

<style scoped>

</style>