<template>
    <div>
        <div v-if="($moment().hour() >= 18 && $moment().hour() <= 23) || ($moment().hour() >= 0 && $moment().hour() < 9)"> {{ getRestTime() }} </div>
        <div v-else> {{counter}} </div>
    </div>
</template>

<script>
    export default {
        name: "countdown",
        props:{
            deadline: {
                type: Number,
                default: 0
            }
        },
        data(){
            return {
                counter: '',
                checkTimeInterval: null
            }
        },
        methods:{
            countdown(){
                this.checkTimeInterval = setInterval(()=> {
                    if( (this.$moment().hour() >= 18 && this.$moment().hour() <= 23) || (this.$moment().hour() >= 0 && this.$moment().hour() < 9) ){
                        clearInterval(this.checkTimeInterval);
                        return;
                    }
                    let now = this.$moment().valueOf();
                    let distance = this.deadline - now;
                    let dash = (distance < 0) ? '- ' : ''
                    distance = Math.abs(distance);
                    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    days = (days > 0) ? days + 'd, ' : '';
                    this.counter = dash + days + hours + "h, " +
                        minutes + "m, " + seconds + "s";

                }, 1000);
            },
            getRestTime(){
                let new_deadline = this.deadline ;
                let nextdate = this.$moment().set({"hour": 18, "minute": 0, "second": 0}).valueOf();
                let deadline =  new_deadline - nextdate;
                let dash = (deadline < 0) ? '- ' : ''
                deadline = Math.abs(deadline)
                let days = Math.floor(deadline / (1000 * 60 * 60 * 24));
                let hours = Math.floor((deadline % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((deadline % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((deadline % (1000 * 60)) / 1000);
                days = (days > 0) ? days + 'd, ' : '';
                return dash + days + hours + " h, " + minutes + " m, " + seconds + " s";
            },
        },
        mounted() {
            this.countdown()
        }
    }
</script>

<style scoped>

</style>