<template>
    <div class="ui fluid card progressionCard p-half">
        <div class="header">
            operateur / Progression
        </div>
        <div class="description mt-1 limited-info">
            <table class="ui very basic table progressTable">
                <thead>
                </thead>
                <tbody>
                    <tr v-for="user in users">
                        <td class="four wide ">
                            <div class="user-info">
                                <avatar :user="user" />
                                <div class="user-description">
                                    <p>{{user._id}}</p>
                                    <p class="user-role">{{user.type}}</p>
                                </div>
                            </div>
                        </td>

                        <td class="twelve wide">
                            <div class="p-half">
                                <div class="ui small progress prg">
                                    <div class="bar" :style="'width: ' + (getStat(user._id, 'encours') * 100) /stats.all + '%;'">
                                        <div class="progress">{{getStat(user._id, 'encours')}}</div>
                                    </div>
                                </div>
                                <div class="ui small progress prg yellow">
                                    <div class="bar" :style="'width: ' + (getStat(user._id, 'en_approche') * 100) /stats.all + '%;'">
                                        <div class="progress">{{getStat(user._id, 'en_approche')}}</div>
                                    </div>
                                </div>
                                <div class="ui small Indicating progress prg red">
                                    <div class="bar" :style="'width: ' + (getStat(user._id, 'hors_delai') * 100) /stats.all + '%;'">
                                        <div class="progress">{{getStat(user._id, 'hors_delai')}}</div>
                                    </div>
                                </div>

                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import avatar from "@/components/avatar.vue";
    export default {
        name: 'operator-progress',
        components:{avatar},
        props:{
            filter:{
                type: Object,
                default:()=>{ return {} }
            }
        },
        data() {
            return {
                users: [],
                stats: {
                    all: 0,
                    encours: 0,
                    en_approche: 0,
                    hors_delai: 0
                }
            }
        },
        methods: {
            getOperatorProgress(){
                log('getOperatorProgress');
                let match = { 'action.task_status': 'active' };
                const now = new Date().getTime();
                let match_dossier = {};
                let date_creation = {}

                if(this.filter.selected_assurance){
                    match_dossier["dossier.assurance._id"] = this.filter.selected_assurance
                }


                if(this.filter.dateDebut || this.filter.dateFin)
                    match.date_creation = this.$store.dateToQuery(this.filter.dateDebut, this.filter.dateFin)

                console.log("MATCH", match);
                let pipeline = [
                    {
                        $match: match
                    },
                    {
                        $facet: {
                            'users': [
                                {
                                    $group: {
                                        _id: "$receiver.username",
                                        type: {$first: "$receiver.type"},
                                        user_image: {$first: "$receiver.user_image"}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ],
                            'all': [
                                {$count: 'total'},
                            ],
                            'encours': [
                                {$match: {deadline_warn: {$gt: now}}},
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {
                                    $group: {
                                        _id: "$receiver.username",
                                        count: {$sum: 1}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ],
                            'en_approche': [
                                {$match: {deadline_warn: {$lt: now},deadline: {$gt: now} } },
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {
                                    $group: {
                                        _id: "$receiver.username",
                                        count: {$sum: 1}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ],
                            'hors_delai': [
                                {
                                    $match: {'deadline': {$lt: now}},
                                },
                                {
                                    $graphLookup: {
                                        from: "documents",
                                        startWith: "$id_doc",
                                        connectFromField: "id_doc",
                                        connectToField: "_id",
                                        maxDepth: 1,
                                        as: "dossier"
                                    }
                                },
                                {$unwind: "$dossier"},
                                {$match: match_dossier},
                                {
                                    $group: {
                                        _id: "$receiver.username",
                                        count: {$sum: 1}
                                    }
                                },
                                {$sort: {"_id": 1}}
                            ]

                        }
                    },
                    {$unwind: {"path": "$all"}}
                ];
                let args = {
                    collection: this.$SHARED.collections.documents,
                    pipeline: pipeline
                };
                this.$store.requestAggregate(args).then(data => {
                    if(!_.isEmpty(data[0])){
                        this.users = data[0].users
                        this.stats.all =  data[0].all.total;
                        this.stats.encours =  data[0].encours;
                        this.stats.en_approche = data[0].en_approche;
                        this.stats.hors_delai = data[0].hors_delai;
                    } else{
                        this.users = []
                        this.stats.all =  0;
                        this.stats.encours =  0;
                        this.stats.en_approche = 0;
                        this.stats.hors_delai = 0;
                    }
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                })
            },
            getStat(user, type){
                let stat = _.find(this.stats[type], {_id: user});
                return stat ? stat.count : 0
            }
        },
        watch:{
            filter:{
                deep: true,
                handler(){
                    this.getOperatorProgress();
                }
            }
        },
        mounted() {
            this.getOperatorProgress();
        }
    }
</script>

<style scoped>

</style>