<template>
    <div class="ui fluid card progressionCard p-half">
        <div class="header">
            operateur / Projet/ status
        </div>
        <div class="description mt-1 progressLabels">
            <div class="mx-auto">
                <div class="progressLabel red active">
                    <div class="title">Hors delai</div>
                    <div class="detail">{{stats.hors_delai}}</div>
                </div>
                <div class="progressLabel orange">
                    <div class="title">En approche</div>
                    <div class="detail">{{stats.en_approche}}</div>
                </div>
                <div class="progressLabel">
                    <div class="title">En cours</div>
                    <div class="detail">{{stats.encours}}</div>
                </div>
            </div>
        </div>
        <div class="description mt-1 limited-info">
            <table class="ui very basic table progressTable">
                <thead>

                </thead>
                <tbody>
                    <tr v-for="user in operator_status">
                    <td>
                        <div class="user-info">
                            <avatar :user="user"/>
                            <div class="user-description">
                                <p>{{user._id}}</p>
                                <p class="user-role red">{{user.user_type}}</p>
                            </div>
                        </div>
                    </td>

                    <td>
                        <div class="user-info">
                            <div class="user-description">
                                <p v-for="assrance in user.assurance">{{assrance}}</p>
                            </div>
                        </div>

                    </td>
                    <td>
                        <div class="user-info">
                            <div class="user-description">
                                <div class="user-count red">{{user.count}}</div>
                            </div>
                        </div>

                    </td>

                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import avatar from "@/components/avatar.vue";
    export default {
        name: 'operator-status',
        components:{avatar},
        props:{
            stats: {
                type: Object,
                default: ()=> { return {} }
            },
            filter:{
                type: Object,
                default:()=>{ return {} }
            }
        },
        data(){
            return{
                operator_status: []
            }
        },
        methods:{
            getOperatorStatus(){
                log('getOperatorProgress');
                let match = { 'action.task_status': 'active' };
                let match_dossier = {};
                let date_creation = {}

                if(this.filter.selected_assurance){
                    match_dossier["dossier.assurance._id"] = this.filter.selected_assurance
                }

                if(this.filter.dateDebut || this.filter.dateFin)
                    match.date_creation = this.$store.dateToQuery(this.filter.dateDebut, this.filter.dateFin)

                let pipeline = [
                    {
                        $match: match
                    },
                    {
                        $graphLookup: {
                            from: "documents",
                            startWith: "$id_doc",
                            connectFromField: "id_doc",
                            connectToField: "_id",
                            maxDepth: 1,
                            as: "dossier"
                        }
                    },
                    {$unwind: "$dossier"},
                    {$match: match_dossier},
                    {
                        $addFields: {
                            user_assurance: {$concat: ["$receiver.username", " - ", "$dossier.assurance._id"]}
                        }
                    },
                    {
                        $group: {
                            _id: "$user_assurance",
                            user: {$first: "$receiver.username"},
                            assurance: {$first: "$dossier.assurance.label"},
                            user_type: {$first: "$receiver.type"},
                            user_image: {$first: "$receiver.user_image"},
                            count: { $sum: 1 }
                        }
                    },
                    {
                        $group: {
                            _id: "$user",
                            count: {$sum: "$count"},
                            assurance: {$push: "$assurance"},
                            user_type: {$first: "$user_type"},
                            user_image: {$first: "$user_image"}
                        }
                    },
                    {$sort: {_id: 1}}
                ];
                let args = {
                    collection: this.$SHARED.collections.documents,
                    pipeline: pipeline
                };
                this.$store.requestAggregate(args).then(data => {
                    this.operator_status = data
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages[code]
                    });
                })
            },
        },
        watch:{
            filter:{
                deep: true,
                handler(){
                    this.getOperatorStatus();
                }
            }
        },
        mounted() {
            this.getOperatorStatus();
        }
    }
</script>

<style scoped>

</style>